<template>
    <div class="w-main">
        <div class="w-main-menu">
            <div class="w-menu-header">
                <div>ChatGPT</div>
                <el-dropdown>
                    <i class="el-icon-arrow-down"></i>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item disabled>添加类别</el-dropdown-item>
                        <el-dropdown-item disabled>添加栏目</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="w-menu-sub">
                <div class="w-menu-label"><span><i class="el-icon-arrow-right"></i>公共频道</span></div>
                <div class="w-menu-item" @click="OnNavChange(null, 'newChat')" :class="{ 'is-active': currentName == 'newChat' }"><i class="el-icon-collection"></i>新的面具</div>

                <div class="w-menu-label"><span><i class="el-icon-arrow-right"></i>我的面具</span></div>
                <div
                    v-for="(item, idx) in chat"
                    :key="idx"
                    class="w-menu-item"
                    @click="OnNavChange(item.id, 'chatWindow')"
                >
                    <i class="el-icon-star-off"></i>
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="w-main-contain">
            <component :is="currentName" :id="currentId" ref="view" v-if="currentVisible" @onUpdate="GetMyChat"></component>
        </div>

        <!-- 提示 -->
        <el-dialog class="w-el-dialog" :visible.sync="dialog" append-to-body title="提示">
            <el-link type="primary"><h2>个人经费有限，暂时只支持GPT-3.5</h2></el-link><br>
            <el-link type="danger"><h2>如果后续反响较好，会升级为GPT-4.0</h2></el-link><br>
            <el-link type="warning"><h2>短期内不在更新，看后续使用情况在优化！</h2></el-link><br>
            上线GPT目的：<br>
            1.尽量收集并解决大家工作中常见问题，望以后能够自动化解决<br>
            2.考虑后期接通工单模块。<br>
            3.希望能够将部分业务交由GPT处理<br>
            4.将GPT作为公司的主要“知识库”<br>
            5.探索GPT的实际应用<br>
            <el-link type="warning"><h2>经费问题，有时候回答会比较慢</h2></el-link><br>
            <el-link type="warning"><h2>大家的数据在OpenAI，管理员都查不到（删除会话后,尽可能别删吧！）。</h2></el-link><br>
        </el-dialog>
    </div>
</template>

<script>
import newChat from "@/views/oa/aiGpt/newChat"
import chatWindow from "@/views/oa/aiGpt/chatWindow"

export default {
    components: {
        newChat,
        chatWindow
    },

    data () {
        return {
            dialog: true,

            currentVisible: true,
            currentId: null,
            currentName: 'newChat',

            chat: {}
        }
    },

    mounted () {
        this.GetMyChat()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 我的会话
        |--------------------------------------------------
        |
        */

        GetMyChat() {
            this.$http.post('/9api/ai-gpt/my-chat').then(rps => {
                this.chat = rps.data
                
                if (this.chat.length > 0) {
                    this.OnNavChange(this.chat[0].id, 'chatWindow')
                }
            })
        },

        /**
        |--------------------------------------------------
        | 菜单选择
        |--------------------------------------------------
        |
        */

        OnNavChange (currentId, currentName) {
            new Promise((resolve) => {
                this.currentId = null,
                this.currentName = null
                this.currentVisible = false
                resolve()
            }).then(() => {
                this.currentId = currentId
                this.currentName = currentName
                this.currentVisible = true
            })
        }
    }
}
</script>
