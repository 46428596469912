<template>
    <div class="w-contain">

        <!-- Header -->
        <div class="w-contain-header">
            <div class="w-contain-header-left">
                <div class="w-contain-header-left-back" @click="$router.back(-1)"><i class="el-icon-back"></i>返回</div>
                <div class="w-contain-header-left-title">
                    <i class="el-icon-collection"></i>{{ chat.name }}
                </div>
            </div>
            <div class="w-contain-header-right">
                <el-button @click="$refs.chatEdit.Open(id)" type="primary" size="small">面具编辑</el-button>
                <el-button @click="DeleteChat()" type="danger" size="small">移除面具</el-button>
            </div>
        </div>

        <!-- 会话 -->
        <div class="w-contain-chat" v-loading="loading">

            <!-- 会话窗口 -->
            <div class="w-contain-chat-window" ref="chatWindow">
                <div v-for="(item, idx) in chatMessage" :key="idx" :class="['window-item', item.role == 'user' ? 'is-right' : '']">
                    <div class="window-item-time">{{ item.created_at }}</div>
                    <div class="window-item-message">
                        <el-avatar v-if="item.role != 'user'" src="/image/nav/operate.png"></el-avatar>
                        <div class="window-item-content">
                            {{ item.content }}
                        </div>
                        <el-avatar v-if="item.role == 'user'" :src="user.avatar"></el-avatar>
                    </div>
                </div>

                <div v-if="sendLoading" class="window-item">
                    <div class="window-item-time">正在努力回复中</div>
                    <div class="window-item-message">
                        <el-avatar src="/image/nav/operate.png"></el-avatar>
                        <div class="window-item-content" style="width: 50%;">
                            <el-skeleton :rows="6" animated  />
                        </div>
                    </div>
                </div>
            </div>

            <!-- 会话底部 -->
            <div class="w-contain-chat-input">
                <!-- 消息框 -->
                <el-input v-model="sendContent" type="textarea" :rows="3" :disabled="sendLoading" placeholder="请输入内容"></el-input>
            </div>

            <!-- 工具栏 -->
            <div class="w-contain-chat-toolbar">
                <div class="toolbar-left">
                    <el-button type="primary" round plain size="mini" icon="el-icon-delete" @click="DeleteChatMessage()">清空记录</el-button>
                </div>
                <div class="toolbar-right">
                    <el-button type="primary" round size="mini" icon="el-icon-position" @click="SendMessage()" :loading="sendLoading">发送</el-button>
                </div>
            </div>
        </div>

        <chatEdit ref="chatEdit" @onSave="$emit('onUpdate')"></chatEdit>
    </div>
</template>

<script>
import chatEdit from "@/views/oa/aiGpt/drawerChatEdit"

export default {
    components: {
        chatEdit
    },

    data () {
        return {
            loading: false,

            user: {},
            chat: {},
            chatMessage: [],

            sendLoading: false,
            sendContent: null,
        }
    },

    props: {
        id: {
            type: Number,
            require: true
        }
    },
    
    mounted () {
        this.GetUser()
        this.GetChat()
        this.GetChatMessage()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 用户信息 - 读取
        |--------------------------------------------------
        |
        */

        GetUser() {
            this.$http.post('/9api/my/info').then(rps => {
                this.user = rps.data
            })
        },

        /**
        |--------------------------------------------------
        | 会话 - 读取
        |--------------------------------------------------
        |
        */

        GetChat() {
            this.$http.post('/9api/ai-gpt/search-id', { id: this.id }).then(rps => {
                this.chat = rps.data
            })
        },
        
        /**
        |--------------------------------------------------
        | 会话消息 - 读取
        |--------------------------------------------------
        |
        */

        GetChatMessage() {
            this.loading = true
            this.$http.post('/9api/ai-gpt/my-message', { id: this.id }).then(rps => {
                this.chatMessage = rps.data
                this.loading = false
            })
        },
        
        /**
        |--------------------------------------------------
        | 会话 - 移除
        |--------------------------------------------------
        |
        */

        DeleteChat() {
            this.loading = true
            this.$http.post('/9api/ai-gpt/delete', { id: this.id }).then(rps => {
                this.chatMessage = rps.data
                this.loading = false
                this.$emit('onUpdate')
            })
        },

        /**
        |--------------------------------------------------
        | 会话消息 - 清空
        |--------------------------------------------------
        |
        */

        DeleteChatMessage() {
            this.loading = true
            this.$http.post('/9api/ai-gpt/delete-message', { id: this.id }).then(rps => {
                this.GetChatMessage()
            })
        },

        /**
        |--------------------------------------------------
        | 会话消息 - 发送
        |--------------------------------------------------
        |
        */

        SendMessage() {
            this.sendLoading = true
            this.chatMessage.push({ role: 'user', 'content': this.sendContent })

            this.$http.post('/9api/ai-gpt/message-send', { id: this.id, content: this.sendContent }).then(rps => {
                this.sendContent = null
                this.chatMessage.push(rps.data)
                this.sendLoading = false
            }).catch(rps => {
                this.sendLoading = false
            })
        },
    },

    updated() {
        let scrollEl = this.$refs.chatWindow;
        scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
    }
}
</script>