<!---------------------------------------------------------------------------- 
 | GPT - 面具编辑
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onSave = fn (Object) => {} 成功后返任务信息
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body>

        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 面具编辑
        </div>

        <!-- body -->
        <div class="w-el-drawer-body">
            <el-row :gutter="20" class="w-margin-b-20">
                <el-col :span="8">
                    <el-card>
                        <div class="w-margin-b-5"><el-link :underline="false" type="primary">面具名称</el-link></div>
                        <div class="w-margin-b-5"><el-link :underline="false" type="info">如果不填则默认为“新的面具”</el-link></div>
                        <el-input size="mini" v-model="chat.name"></el-input>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div class="w-margin-b-5"><el-link :underline="false" type="primary">模型</el-link></div>
                        <div class="w-margin-b-5"><el-link :underline="false" type="info">建议使用GPT-4</el-link></div>
                        <el-select size="mini" v-model="chat.model" placeholder="请选择">
                            <el-option label="gpt-4" value="gpt-4"></el-option>
                            <el-option label="gpt-4-0613" value="gpt-4-0613"></el-option>
                            <el-option label="gpt-4-32k" value="gpt-4-32k"></el-option>
                            <el-option label="gpt-4-32k-0613" value="gpt-4-32k-0613"></el-option>
                            <el-option label="gpt-4-0314 (Legacy)" value="gpt-4-0314 (Legacy)"></el-option>
                            <el-option label="gpt-4-32k-0314 (Legacy)" value="gpt-4-32k-0314 (Legacy)"></el-option>
                            <el-option label="gpt-3.5-turbo" value="gpt-3.5-turbo"></el-option>
                            <el-option label="gpt-3.5-turbo-16k" value="gpt-3.5-turbo-16k"></el-option>
                            <el-option label="gpt-3.5-turbo-instruct" value="gpt-3.5-turbo-instruct"></el-option>
                            <el-option label="gpt-3.5-turbo-0613" value="gpt-3.5-turbo-0613"></el-option>
                            <el-option label="gpt-3.5-turbo-16k-0613" value="gpt-3.5-turbo-16k-0613"></el-option>
                            <el-option label="gpt-3.5-turbo-0301 (Legacy)" value="gpt-3.5-turbo-0301 (Legacy)"></el-option>
                            <el-option label="text-davinci-003 (Legacy)" value="text-davinci-003 (Legacy)"></el-option>
                            <el-option label="text-davinci-002 (Legacy)" value="text-davinci-002 (Legacy)"></el-option>
                            <el-option label="code-davinci-002 (Legacy)" value="code-davinci-002 (Legacy)"></el-option>
                        </el-select>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div class="w-margin-b-5"><el-link :underline="false" type="primary">自定义KEY</el-link></div>
                        <div class="w-margin-b-5"><el-link :underline="false" type="info">可以使用自己的KEY</el-link></div>
                        <el-input size="mini" v-model="chat.name"></el-input>
                    </el-card>
                </el-col>
            </el-row>

            <el-row :gutter="20" class="w-margin-b-20">
                <el-col :span="8">
                    <el-card>
                        <div class="w-margin-b-5"><el-link :underline="false" type="primary">随机性</el-link></div>
                        <div class="w-margin-b-5"><el-link :underline="false" type="info">值越大,回复越随机</el-link></div>
                        <el-input-number size="mini" class="w-width-auto" v-model="chat.temperature" :min="0" :max="1" :step="0.1"></el-input-number>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div class="w-margin-b-5"><el-link :underline="false" type="primary">核采样</el-link></div>
                        <div class="w-margin-b-5"><el-link :underline="false" type="info">与随机性类似，但不要和随机性一起更改</el-link></div>
                        <el-input-number size="mini" class="w-width-auto" v-model="chat.top_p" :min="0" :max="1" :step="0.1"></el-input-number>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div class="w-margin-b-5"><el-link :underline="false" type="primary">单词回复限制</el-link></div>
                        <div class="w-margin-b-5"><el-link :underline="false" type="info">单词所用的最大token数</el-link></div>
                        <el-input-number size="mini" class="w-width-auto" v-model="chat.max_tokens" :min="0" :max="9999" :step="0"></el-input-number>
                    </el-card>
                </el-col>
            </el-row>

            <el-row :gutter="20" class="w-margin-b-20">
                <el-col :span="8">
                    <el-card>
                        <div class="w-margin-b-5"><el-link :underline="false" type="primary">话题新鲜度</el-link></div>
                        <div class="w-margin-b-5"><el-link :underline="false" type="info">值越大，越有可能扩展到新话题</el-link></div>
                        <el-input-number size="mini" class="w-width-auto" v-model="chat.presence_penalty" :min="-2" :max="2" :step="0"></el-input-number>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div class="w-margin-b-5"><el-link :underline="false" type="primary">频率惩罚度</el-link></div>
                        <div class="w-margin-b-5"><el-link :underline="false" type="info">值越大，越有可能降低重复字词</el-link></div>
                        <el-input-number size="mini" class="w-width-auto" v-model="chat.frequency_penalty" :min="-2" :max="2" :step="0"></el-input-number>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div class="w-margin-b-5"><el-link :underline="false" type="primary">附带历史消息数</el-link></div>
                        <div class="w-margin-b-5"><el-link :underline="false" type="info">每次携带的历史消息数量</el-link></div>
                        <el-input-number size="mini" class="w-width-auto" v-model="chat.send_length" :min="0" :max="64" :step="0"></el-input-number>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="Close()">关 闭</el-button>
            <el-button size="medium" type="primary" @click="SubmitForm()" :disabled="loading">确定</el-button>
        </div>
    </el-drawer>
</template>

<script>
export default {
    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,

            // 表单
            chat: {
                id: null,
                name: null,
                model: null,
                temperature: null,
                top_p: null,
                max_tokens: null,
                presence_penalty: null,
                frequency_penalty: null,
                send_length: null
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open (id) {
            this.visible = true
            this.loading = true
            this.$http.post('/9api/ai-gpt/search-id', { id: id }).then(rps => {
                this.chat = rps.data
                this.loading = false
            })
        },
        

        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.loading = false
            this.visible = false
        },
        

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm () {
            this.loading = true
            this.$http.post('/9api/ai-gpt/edit', this.chat).then(rps => {
                this.$emit('onSave', rps.data)
                this.loading = false
                this.visible = false
            })
        },
    },
}
</script>