<template>
    <div class="w-contain" style="background-color: #FFFFFF;">

        <!-- Header -->
        <div class="w-contain-header">
            <div class="w-contain-header-left">
                <div class="w-contain-header-left-back" @click="$router.back(-1)"><i class="el-icon-back"></i>返回</div>
                <div class="w-contain-header-left-title">
                    <i class="el-icon-collection"></i>New ChatGPT
                </div>
            </div>
        </div>

        <!-- 内容 -->
        <div class="w-contain-body">

            <!-- Banner -->
            <div style="text-align: center;"><h1>挑选一个面具</h1></div>
            <div style="text-align: center;"><h4>现在开始,与面具背后的灵魂思维碰撞</h4></div>

            <!-- Search -->
            <div class="w-margin-b-20">
                <el-input v-model="masksSearch.name" placeholder="面具名称">
                    <el-button slot="append" @click="CreateChat()">直接开始</el-button>
                </el-input>
            </div>

            <!-- List -->
            <el-row :gutter="20">
                <el-col :span="8" v-for="(item, idx) in masks" :key="idx" class="w-margin-b-20">
                    <el-card shadow="hover" style="cursor: pointer;" @click.native="CreateChat(item.id)">
                        <i class="el-icon-edit"></i>{{ item.name }}
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            masks: [],
            masksSearch: {
                name: null
            }
        }
    },

    mounted() {
        this.$http.post('/9api/ai-gpt/masks', { hidden: ['presets'] } ).then(rps => {
            this.masks = rps.data
        })
    },

    methods: {
        CreateChat(id) {
            this.$http.post('/9api/ai-gpt/create', { id: id } ).then(rps => {
                this.$emit('onUpdate')
            })
        }
    }
}
</script>

